import React from 'react'

const NotFound = () => {
    return (
        <div className='mt-20'>
            <div className='row text-center'>
                <div> <h2 className='text-danger'>404 PAGE NOT FOUND </h2></div>
            </div>
        </div>
    )
}

export default NotFound